import React from 'react';
import PropTypes from 'prop-types';

const Testimonials = (props) => {
  const { testimonials } = props;

  return (<>{testimonials && <>
    <h2 className="has-text-weight-semibold is-size-2">
      Testimonials
    </h2>

    <div>
      {testimonials.map((testimonial, idx) => (
        <article className="message" key={idx}>
          <div className="message-body">
            {testimonial.quote}
            <br />
            <cite>
              {' '}
              – {testimonial.author}
              <sup>*</sup>
            </cite>
          </div>
        </article>
      ))}
      <p className="message-footer">
        <sup>*</sup>All names have been changed to protect patient
        confidentiality
      </p>
    </div>
  </>
  }</>);
};
Testimonials.propTypes = {
  testimonials: PropTypes.arrayOf(
    PropTypes.shape({
      quote: PropTypes.string,
      author: PropTypes.string,
    })
  ),
};

export default Testimonials;
