import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import bulmaCollapsible from '@creativebulma/bulma-collapsible/src/js/index.js';

const Questions = (props) => {
  const { questions } = props;
  const collapsibles = useCallback((node) => {
    if (node !== null) {
      bulmaCollapsible.attach('.is-collapsible', {
        container: node,
      });
    }
  });

  return (<>{questions && <><h2 className="has-text-weight-semibold is-size-2">
    Fequently Asked Qestions
  </h2>
    <div id="faqs" ref={collapsibles}>
      {questions.map((question, idx) => (
        <article className="message" key={idx}>
          <div
            className="message-header"
            href={`#question-${idx}`}
            data-action="collapse"
          >
            {question.question}
          </div>
          <div
            id={`question-${idx}`}
            className="message-body is-collapsible"
            data-parent="faqs"
          >
            {question.answer}
          </div>
        </article>
      ))}
    </div></>}</>
  );
};
/* class Questions extends React.Component {
  componentDidMount() {
    this.collapsibles = bulmaCollapsible.attach('.is-collapsible', {
      container: this.refs.collapsibles,
    });
  }

  render() {
    return (
      <div ref="collapsibles" id="accordion_first">
        <article className="message">
          <div className="message-header">
            <p>
              Question 1{' '}
              <a href="#collapsible-message-accordion-1" data-action="collapse">
                Collapse/Expand
              </a>
            </p>
          </div>
          <div
            id="collapsible-message-accordion-1"
            className="message-body is-collapsible is-active"
            data-parent="accordion_first"
          >
            <div className="message-body-content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.{' '}
              <strong>Pellentesque risus mi</strong>, tempus quis placerat ut,
              porta nec nulla. Vestibulum rhoncus ac ex sit amet fringilla.
              Nullam gravida purus diam, et dictum
              <a>felis venenatis</a> efficitur. Aenean ac{' '}
              <em>eleifend lacus</em>, in mollis lectus. Donec sodales, arcu et
              sollicitudin porttitor, tortor urna tempor ligula, id porttitor mi
              magna a neque. Donec dui urna, vehicula et sem eget, facilisis
              sodales sem.
            </div>
          </div>
        </article>
      </div>
    );
  }
} */
Questions.propTypes = {
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: PropTypes.string,
    })
  ),
};

export default Questions;
